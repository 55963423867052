<template>
  <div v-if="scoresValues.length > 0">
    <h2 class="heading-3">{{ i18n(translations.scoreTitle) }}</h2>
    <div class="mt-8 space-y-8 md:mt-4 md:space-y-4">
      <div
        v-for="{ label, value } in scoresValues"
        :key="label"
        data-test="score"
      >
        <p class="body-2">
          {{ label }} <span class="sr-only">{{ `${value}%` }}</span>
        </p>
        <ProductScore class="mt-4" :label="label" :value="value" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { Scores } from '@backmarket/nuxt-layer-recommendation/models/product'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import ProductScore from './ProductScore.vue'
import translations from './ProductScores.translations'

const i18n = useI18n()
const { scores } = defineProps<{
  scores: Scores
}>()

const scoresValues = computed(() => [
  ...(scores.lifeExpectancy
    ? [
        {
          label: i18n(translations.scoreLifeExpectancy),
          value: parseFloat(scores.lifeExpectancy),
        },
      ]
    : []),
  ...(scores.performances
    ? [
        {
          label: i18n(translations.scorePerformances),
          value: parseFloat(scores.performances),
        },
      ]
    : []),
  ...(scores.camera
    ? [
        {
          label: i18n(translations.scoreCamera),
          value: parseFloat(scores.camera),
        },
      ]
    : []),
  ...(scores.screenQuality
    ? [
        {
          label: i18n(translations.scoreScreenQuality),
          value: parseFloat(scores.screenQuality),
        },
      ]
    : []),
  ...(scores.soundQuality
    ? [
        {
          label: i18n(translations.scoreSoundQuality),
          value: parseFloat(scores.soundQuality),
        },
      ]
    : []),
  ...(scores.multimedia
    ? [
        {
          label: i18n(translations.scoreMultimedia),
          value: parseFloat(scores.multimedia),
        },
      ]
    : []),
])
</script>
