<template>
  <div>
    <RevBreadcrumb
      v-if="links.length"
      :ariaLabel="i18n(translations.breadcrumb)"
      :breads="links"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevBreadcrumb } from '@ds/components/Breadcrumb'

import translations from './Breadcrumb.translations'

const props = defineProps<{
  breads: Array<{ label: string; url: string }>
}>()

const i18n = useI18n()

const links = computed(() =>
  props.breads.map(({ label, url }) => ({
    title: label,
    link: url,
    tracking: {
      zone: 'product_list',
      value: 'breadcrumb',
      name: label,
    },
  })),
)
</script>
