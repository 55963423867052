<template>
  <div>
    <p class="body-2">
      <template v-if="taglineEnd">
        {{ taglineStart
        }}<span :class="{ 'hidden md:inline': !isTaglineExpanded }">{{
          taglineEnd
        }}</span>
        <span :class="isTaglineExpanded ? 'hidden' : 'md:hidden'">...</span>
      </template>
      <template v-else>
        {{ tagline }}
      </template>
    </p>
    <RevLink
      v-if="taglineEnd"
      class="body-2-link md:hidden"
      @click="isTaglineExpanded = !isTaglineExpanded"
    >
      {{
        isTaglineExpanded
          ? i18n(translations.viewLess)
          : i18n(translations.viewMore)
      }}
    </RevLink>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevLink } from '@ds/components/Link'

import translations from './Tagline.translations'

const i18n = useI18n()

const props = defineProps<{
  tagline: string
}>()

const taglineTrimmedLength = 46
const taglineStart = props.tagline?.substring(0, taglineTrimmedLength)
const taglineEnd = props.tagline?.substring(
  taglineTrimmedLength,
  props.tagline.length,
)
const isTaglineExpanded = ref(false)
</script>
