import { computed } from 'vue'

import { getSeoTags } from '@backmarket/http-api/src/api-specs-acquisition/link-booster/tags'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'

export async function useProductListTags(id: string) {
  const { data, error } = await useHttpFetch(getSeoTags, {
    queryParams: { pk: id, model: 'landing' },
  })

  const tags = computed(() => {
    if (error.value || !data.value || data.value?.length === 0) {
      return null
    }

    return data.value
  })

  return {
    tags,
  }
}
