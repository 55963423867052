<template>
  <div class="md:flex md:flex-wrap md:flex-nowrap md:items-center">
    <div
      class="flex w-full max-w-full flex-col items-center gap-y-4 md:w-1/3 lg:w-1/2"
    >
      <RevIllustration
        :alt="product.title"
        class="h-[66.7vw] w-auto md:h-auto lg:max-w-[24rem]"
        :height="384"
        :src="product.image"
        :width="384"
      />
      <ColorSwatches
        v-if="product.specifications?.color"
        :colors="product.specifications.color"
      />
    </div>
    <div class="mt-24 w-full max-w-full md:mt-0 md:w-2/3 lg:w-1/2 lg:flex-1">
      <h1 class="heading-1">{{ title }}</h1>
      <RatingInfo
        v-if="product.reviewRating"
        :rating="{
          count: product.reviewRating.count,
          averageRate: product.reviewRating.average,
        }"
      />
      <div
        class="mt-24 space-y-12 md:grid md:grid-cols-2 md:items-center md:gap-16 md:space-y-0"
      >
        <div>
          <span class="caption-bold block">{{
            i18n(translations.startingFrom)
          }}</span>
          <div class="flex flex-wrap items-baseline gap-x-4">
            <span class="heading-2">
              {{ i18n.price(product.price) }}
            </span>
            <span
              v-if="
                COUNTRIES_WITH_TRADE_IN_PRICE_LABEL_DISCOUNTED_SWAP.includes(
                  countryCode,
                )
              "
              class="body-2-bold whitespace-nowrap"
            >
              {{ i18n(translations.beforeTradeIn) }}
            </span>
          </div>
          <div
            v-if="product.priceNew"
            class="flex flex-wrap items-center gap-x-8"
          >
            <span
              class="text-static-default-low body-2-striked cursor-pointer whitespace-nowrap"
            >
              {{ i18n.price(product.priceNew) }}
              {{ i18n(translations.newKeyword) }}
            </span>
            <RevTag
              v-if="savings"
              :label="
                i18n(translations.youSave, {
                  reduction: i18n.price(savings),
                })
              "
              variant="success"
            />
          </div>
        </div>
        <RevButton
          class="w-full md:w-auto md:min-w-[164px] md:grow"
          :to="product.productPageLink"
          variant="primary"
        >
          {{ i18n(translations.seeOptions) }}
        </RevButton>
      </div>
      <div v-if="product.scores" class="mt-24 md:grid md:grid-cols-2 md:gap-16">
        <ProductScores :scores="product.scores" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import ColorSwatches from '@backmarket/nuxt-layer-recommendation/ColorSwatches.vue'
import type { Product } from '@backmarket/nuxt-layer-recommendation/models/product'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { RevButton } from '@ds/components/Button'
import { RevIllustration } from '@ds/components/Illustration'
import { RevTag } from '@ds/components/Tag'

import { COUNTRIES_WITH_TRADE_IN_PRICE_LABEL_DISCOUNTED_SWAP } from '~/scopes/product/components/Price/Price.constants'
import RatingInfo from '~/scopes/reviews/reviews-display/components/Rating/Rating.vue'

import translations from './Hero.translations'
import ProductScores from './ProductScores.vue'

const {
  market: { countryCode },
} = useMarketplace()

const { product, title } = defineProps<{
  product: Product
  title: string
}>()

const i18n = useI18n()

const savings = computed(() => {
  const priceNew = parseFloat(product.priceNew?.amount ?? '0')
  const savingsAmount = priceNew - parseFloat(product.price.amount)
  if (!savingsAmount) return null

  return {
    amount: savingsAmount.toString(),
    currency: product.price.currency,
  }
})
</script>
