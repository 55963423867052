export default {
  seeOptions: {
    id: 'product_hero_see_options',
    defaultMessage: 'See options',
  },
  startingFrom: {
    id: 'product_hero_starting_from',
    defaultMessage: 'Starting from',
  },
  beforeTradeIn: {
    id: 'product_hero_trade_in',
    defaultMessage: 'before trade-in',
  },
  newKeyword: {
    id: 'product_hero_new_keyword',
    defaultMessage: 'new',
  },
  youSave: {
    id: 'product_hero_you_save_amount_display',
    defaultMessage: 'Save {reduction}',
  },
}
