export default {
  scoreTitle: {
    id: 'back_market_score',
    defaultMessage: 'Back Market scores',
  },
  scoreLifeExpectancy: {
    id: 'life_expectancy_score',
    defaultMessage: 'Life expectancy',
  },
  scorePerformances: {
    id: 'performances_score',
    defaultMessage: 'Performances',
  },
  scoreCamera: {
    id: 'camera_score',
    defaultMessage: 'Camera',
  },
  scoreScreenQuality: {
    id: 'screen_quality_score',
    defaultMessage: 'Screen quality',
  },
  scoreSoundQuality: {
    id: 'sound_quality_score',
    defaultMessage: 'Sound quality',
  },
  scoreMultimedia: {
    id: 'multimedia_score',
    defaultMessage: 'Multimedia',
  },
}
