import { useRoute } from '#imports'
import { computed } from 'vue'

import { landingReviewsAPI } from '@backmarket/http-api'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import { insertIf } from '@backmarket/utils/collection/insertIf'

import { useGetOrderByParamValue } from '~/scopes/reviews/reviews-display/composables/useGetOrderByParamValue'
import { transformReviewsResponse } from '~/scopes/reviews/reviews-display/utils/transformReviewsResponse'

const { getRating, getReviews } = landingReviewsAPI

export function useProductListRating(id: string) {
  return useHttpFetch(getRating, {
    pathParams: { uuid: id },
  })
}
export function useProductListReviews(id: string) {
  const route = useRoute()
  const locale = useI18nLocale()
  const reviewsOrderByQueryParam = useGetOrderByParamValue()

  const queryParams = computed(() => {
    return {
      ...route.query,
      translation_locale: locale,
      ...insertIf(!!reviewsOrderByQueryParam, {
        order_by: reviewsOrderByQueryParam,
      }),
    }
  })

  return useHttpFetch(getReviews, {
    pathParams: { uuid: id },
    queryParams,
    transform: ({ results }) => ({
      results: transformReviewsResponse(results),
    }),
  })
}
